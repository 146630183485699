<template>
  <div
    class="komodo-errors"
  >
    <div
      v-for="error in errors"
      :key="error"
      class="komodo-error"
      data-test="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'KomodoErrors'
}
</script>

<script setup lang="ts">
defineProps<{
  errors: string[]
}>()
</script>

<style lang="scss" scoped>
.komodo-errors {
  margin-top: 4px;

  .komodo-error {
    @include font-paragraph-p3-small-compact;

    color: $accent-red-1-base;
  }
}
</style>
